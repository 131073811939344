import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header
  let menu

  if (location.pathname === rootPath) {
    menu = (
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <p>
          <Link
            style={{
              boxShadow: `1`,
              color: `inherit`,
            }}
            to={`/enlaces`}
          >
            Enlaces
          </Link>
          </p>
      </div>
    )
    header = (
      <h1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    menu = (
      <div style={{display: 'flex', justifyContent: 'flex'}}>
        <p>
          <Link
            style={{
              boxShadow: `1`,
              color: `inherit`,
            }}
            to={`/`}
          >
            Publicaciones
          </Link>
          </p>
      </div>
    )
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(34),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>{menu}{header}</header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}, Hecho con ♥︎ desde València.
      </footer>
    </div>
  )
}

export default Layout
